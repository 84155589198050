import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { FaGithub } from "react-icons/fa"
import Img1 from "../../images/project_card_icons/bellcurve_video_game/bellcurve_video_game.jpg"
import Img2 from "../../images/project_card_icons/bellcurve_video_game/bellcurve_video_game2.jpg"
import Img3 from "../../images/project_card_icons/bellcurve_video_game/bellcurve_video_game3.jpg"

const ProjectFLCWebsite = () => (
  <Layout>
    <SEO title="FLC Website" />
    <section className="projects__page">
    <h1 className="page-title dark-theme-text">Project Highlights</h1>
      <div className="projects__card--large">
        <div className="projects__card__title">
          <h3>Bellcurve Video Game</h3>
          <p>I like video games, code, and probability, so I made an app that incorporates all three.</p>
        </div>
        <div className="projects__card__description--large">
          <img src={ Img1 } />
          <p>
            <section className="projects__page__subtitles">How <a href="https://arcade.makecode.com/94048-60915-83783-32937" target="_blank" rel="noopener noreferrer">Bellcurve</a>, the video game, works:
            </section>
            <ul>
              <li>Objective is to get across the lava lake to get the treasure chest on the other side.</li>
              <li>First half of the map is randomly populated with <q>?</q> blocks.</li>
              <li>Second half is just lava, until you build the Bellcurve Bridge, that is!</li>
              <li>Activating a <q>?</q> will convert it to a random number between 1 and 6 (like a 6-sided die).</li>
              <li>One bridge piece is created by activating two <q>?</q> blocks.</li>
              <li>Placement of the bridge pieces depends on the sum of each pair of random numbers, thus creating a bridge in the shape of a bellcurve (theoretically).</li>
            </ul>
          </p>
        </div>
        <div className="projects__card__description--large">
          <img src={ Img3 } />
          <p>
            <section className="projects__page__subtitles">The fun part of the <a href="https://github.com/Junosprite007/Bellcurve/blob/master/main.ts" target="_blank" rel="noopener noreferrer">code</a>.</section>
            <ul>
              <li>The red box shows how the map is generated, which was probably the most time-consuming part of this project.</li>
              <li>80 <q>?</q> blocks are created and randomly positioned throughout the map.</li>
              <li>This chuck of code ensures that there are always exactly 80 <q>?</q> blocks on the map.</li>
              <li>No block is created at the same position as an existing block. Instead, the for loop tries to place the current <q>?</q> block at another location.</li>
              <li>An example of a Bellcurve Bride is shown on the left side of the image. You can see the player standing on the randomly generated bridge pieces. Kind of looks like a bellcurve, right?</li>
            </ul>
          </p>
        </div>
        <div className="projects__card__description--large">
          <img src={ Img2 } />
          <p>
            <section className="projects__page__subtitles">The <a href="https://github.com/Junosprite007/Bellcurve/blob/master/main.ts" target="_blank" rel="noopener noreferrer">code</a> that could be more efficient.</section>
            <ul>
              <li>The red square shows some inefficient code. Not exactly a perfect game, but I still think it's pretty fun.</li>
              <li>Using multiple event loops to not only set the player's position, but to change her velocity, as well, has its downsides, known as lag.</li>
              <li>Cleaning up this code would take some repositioning of the onEvent() functions and handling the player's changing attributes in a more modular way.</li>
            </ul>
          </p>
        </div>
        <div className="projects__card__links">
          <a href="https://arcade.makecode.com/94048-60915-83783-32937" target="_blank" rel="noopener noreferrer" className="btn--primary">Play this game!</a>
          <Link to="../" className="btn--secondary">Go back</Link>
          <a href="https://github.com/Junosprite007/Bellcurve" target="_blank" rel="noopener noreferrer" className="social-media"><FaGithub /></a>
        </div>
      </div>
    </section>
  </Layout>
)

export default ProjectFLCWebsite
